<template>
  <div class="page flex_column">
    <div class="number">{{ roomName }}<span>室</span></div>
    <div class="info_box" v-show="list.length > 0">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div v-if="item !== null" class="flex_between">
          <div class="flex_align">
            <van-image class="head_img" fit="cover" round :src="item.headImg" />
            <div class="flex_column">
              <div class="name">{{ item.name }}</div>
              <div class="info">{{ item.clazz }}</div>
              <div class="info code">{{ item.code }}</div>
            </div>
          </div>
          <div class="flex_stretch">
            <a class="flex_center" v-show="item.phone !== null" :href="`tel:${item.phone}`"><van-image class="phone" :src="require('@/pic/roomStatusPhone.png')" /></a>
          </div>
        </div>
        <div v-else>
          <div class="empty flex_center">
            <span class="index">{{ index + 1 }}</span>
            暂无入住
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { getBedroomStudent } from '@/api/dormitory'

export default {
  props: {
    bedroomId: {},
    roomName: {}
  },
  name: 'roomStatus',
  data () {
    return {
      number: '101',
      list: [
        null,
        {
          id: 1,
          headImg: 'https://img01.yzcdn.cn/vant/cat.jpeg',
          name: '暂无人员入住',
          clazz: '',
          code: '',
          phone: ''
        }
      ]
    }
  },
  activated () {
    this.getData()
  },
  methods: {
    getData () {
      // getBedroomStudent
      const data = {
        bedroomId: this.bedroomId
      }
      this.list = []
      getBedroomStudent(data).then(res => {
        const { data: r } = res
        const { data, msg, code, message } = r
        if (code === 0) {
          for (let i = 0; i < data.length; i++) {
            const item = data[i]
            if (item === null) {
              this.list.push(null)
              continue
            }
            const { code, img: headImg, name, clazz, phone } = item
            this.list.push({ id: code, headImg, name, clazz, code, phone })
          }
        } else {
          this.$toast.fail(msg || message)
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
@import '../../assets/transition';
.page {
  background: linear-gradient(180deg, #5D84FE 0%, #8AA6FE 50%, #E1E8FF 100%);
  position: relative;
  z-index: 1;
  &:before {
    content: '';
    width: 180px; height: 180px;
    border-radius: 50%;
    border: 60px solid #7696F8;
    position: absolute;
    top: -150px; right: -150px
  }
  &:after {
    content: '';
    width: 180px; height: 180px;
    border-radius: 50%;
    border: 60px solid #7696F8;
    position: absolute;
    top: 150px; left: -150px
  }
  .number {
    margin-top: 40px;
    padding-left: 24px;
    font-size: 40px;
    color: #FFF;
    & > span {
      font-size: 24px;
      color: #FFF
    }
    margin-bottom: 15px;
  }
  .info_box {
    max-height: 480px;
    padding: 17px 4px;
    background: #FFFFFF;
    border-radius: 25px;
    margin: 0 20px;
    position: relative;
    z-index: 2;
    overflow-y: auto;
    .item {
      padding: 13px 13px;
      &:not(:last-child) {
        border-bottom: 1px solid #E6EAF0;
      }
      .head_img {
        width: 50px; height: 50px;
        margin-right: 13px;
      }
      .name {
        font-size: 15px;
        color: #3D444D;
        margin-bottom: 3px;
      }
      .info {
        font-size: 12px;
        color: #BDC4CE;
        margin-bottom: 3px;
      }
      .code {
        color: #8C9198;
      }
      .phone {
        width: 18px; height: 27px;
        margin-right: 20px;
      }
      .empty {
        height: 50px;
        font-weight: bold;
        font-size: 21px;
        color: #E4E4E4;
        text-align: center;
        position: relative;
        .index {
          font-size: 21px;
          position: absolute;
          top: 50%; left: 20px;
          transform: translate(0, -50%);
        }
      }
    }
  }
}
</style>
